import 'url-search-params-polyfill';

/**
 * UI Tabs
 *
 * @version 2.0.0.0
 */
($ => {
    /**
     * Document Ready
     *
     * @return    {Void}          ~~~~ Undefined ~~~~
     */
    $(() => {
        const elements = $('.rb-tabs-1');

        if (elements.length) {
            const params = new URLSearchParams(location.search);

            elements.each(function () {
                const elementId = $(this).attr('id');
                const elementRouting = $(this).data('route');

                if (elementRouting) {
                    const routeElement = params.has(elementRouting) ? params.get(elementRouting) : false;

                    if (routeElement) {
                        $(`#${elementId} .rb-tabs-1-toggle > ul > li > a`).removeClass('active');
                        $(`#${elementId} .rb-tabs-1-item`).removeClass('active');

                        const anchor = routeElement.replace('ui-', '#');

                        $(anchor).addClass('active');
                        $('[href="#' + routeElement + '"]').addClass('active');
                    }
                }

                $(`#${elementId} .rb-tabs-1-toggle > ul > li > a`).on('click', function (e) {
                    e.preventDefault();

                    const anchor = $(this).attr('href').replace('ui-', '');

                    $(`#${elementId} .rb-tabs-1-item`).removeClass('active');
                    $(`#${elementId} .rb-tabs-1-toggle > ul > li > a`).removeClass('active');

                    $(this).addClass('active');
                    $(anchor).addClass('active');
                });
            });
        }
    });
})(jQuery);
