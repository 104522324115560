import 'owl.carousel';

(function ($) {
    /**
     * Featured Products Carousel
     */
    $(function () {
        $('.owl-carousel.rb-carousel-1').owlCarousel({
            margin: 30,
            responsive: {
                0: {
                    items: 2
                },
                768: {
                    items: 3
                },
                981: {
                    items: 4
                }
            }
        });
    });

    /**
     * Sponsors Carousel
     */
    $(function () {
        $('.owl-carousel.rb-carousel-2').owlCarousel({
            margin: 30,
            loop: true,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 2
                },
                768: {
                    items: 3
                },
                981: {
                    items: 4
                }
            }
        });
    });

    /**
     * Sidebar Carousel
     */
    $(function () {
        $('.owl-carousel.rb-carousel-3').owlCarousel({
            margin: 30,
            loop: true,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 2
                },
                // 768: {
                //     items: 3
                // },
                992: {
                    items: 1
                }
            }
        });
    });
})(jQuery);
