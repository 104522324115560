export default function AjaxRequest(action, variables = {}, params = {}) {
    const defaultData = {
        action,
        security: rgb.security
    };

    const formattedData = { ...defaultData, ...variables };

    const parameters = {
        url: rgb.ajaxURL,
        type: 'post',
        dataType: 'json',
        data: formattedData
    };

    return $.ajax({ ...parameters, ...params });
}
