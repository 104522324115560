(function ($) {
    /**
     * Empty Links
     */
    $(function () {
        $('.empty-link > a').each(function() {
            let $link = $(this);
            let $text = $link.text();
            
            $link.replaceWith('<span>' + $text + '</span>');
        });
    });
})(jQuery);
