/* Validate Group Name */
var keyPressTimer = 0;
var groupNameCallCount = 0;

function valid_group_name(name) {
    return $.ajax({
        url: ajaxurl,
        type: 'POST',
        data: {
            action: 'validate_group_name',
            group_name: name
        }
    });
}

function toggle_enable_create_group_submit(groupName, submit) {
    $(submit).addClass('disabled').prop('disabled', true);
    $('#group_name_validation_notice').hide();

    if (groupName.length > 2) {
        clearTimeout(keyPressTimer);

        keyPressTimer = setTimeout(function () {
            var spinner = $('.small-loader').css('display', 'inline-block');

            groupNameCallCount += 1;

            valid_group_name(groupName).done(function (obj) {
                groupNameCallCount -= 1;

                if (groupNameCallCount > 0) return;

                spinner.hide();

                if (obj.valid) {
                    $(submit).removeClass('disabled').prop('disabled', false);
                    $('#group_name_validation_notice').hide();
                }

                if (obj.message.length > 0) {
                    $('#group_name_validation_notice').html(obj.message).show();
                }
            });
        }, 500);
    }
}

$(() => {
    $('#create-group-form').on('keyup', '#group-name', function (e) {
        var groupName = $('#create-group-form #group-name').val();
        toggle_enable_create_group_submit(groupName, '#group-creation-create');
    });

    $('#group-settings-form').on('keyup', '#group-name', function (e) {
        var groupName = $('#group-settings-form #group-name').val();
        toggle_enable_create_group_submit(groupName, '#save');
    });
});
