import 'select2';

import './shortcodes/Map';
import './controllers/UITabs';
import './components/GoogleMap';
import './components/Carousels';
import './components/Header';

import './components/Legacy';

import './components/Custom';

// Custom

$(() => {
    $('button[data-toggle]').on('click', function () {
        const id = $(this).data('target'),
            item = $(id),
            active = 'display-block';

        if (item.hasClass(active)) item.removeClass(active);
        else item.addClass(active);
    });

    $('#selectcategories').on('change', function () {
        window.location.href = this.value;
    });

    const commentSubmit = $('#comment-wrap #submit');
    const commentArea = $('#comment-wrap #comment');

    commentSubmit.prop('disabled', true); // By default

    commentArea.on('input', function () {
        const state = commentSubmit.prop('disabled');
        const text = $(this).val().replace(/\s/g, '');

        if ('' === text) {
            if (!state) {
                commentSubmit.prop('disabled', true);
            }
        } else {
            if (state) {
                commentSubmit.prop('disabled', false);
            }
        }
    });

    const leftItem = $('.left-menu-title');
    const activeItem = $('.resp-tab-active.active > a');

    if (leftItem.length && activeItem.length) {
        leftItem.html('<div><a id="user-activity">' + activeItem.html() + '</a></div>');
    }
});
