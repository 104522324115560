import 'owl.carousel';
import { mapStyles, polyLineDrawerColor /** mapBgColor */ } from '../styles/Map';
import { zoomToObject } from '../helpers/mapHelpers';
import AjaxRequest from '../controllers/AjaxRequest';

(function ($) {
    $(function () {
        let map = null;
        let clickedItem = false;
        let timeId = null;
        let currentRequest = null;
        let currentPosition = null;
        let historyOfPolyLines = [];
        let historyOfMarkersAdded = [];
        const mapLoader = $('#map-loader');
        const mapContainer = $('#rb-map-google');

        if (mapContainer.length) {
            map = new google.maps.Map(mapContainer[0], {
                zoom: 15,
                disableDefaultUI: true,
                styles: mapStyles
                // backgroundColor: mapBgColor
            });
        }

        function clearMap() {
            if (historyOfPolyLines.length) {
                historyOfPolyLines.forEach(function (polyLine) {
                    polyLine.setMap(null);
                });

                historyOfPolyLines = [];
            }

            if (historyOfMarkersAdded.length) {
                historyOfMarkersAdded.forEach(function (marker) {
                    marker.setMap(null);
                });

                historyOfMarkersAdded = [];
            }
        }

        function handleMapData(townId, href) {
            if (!townId) return false;
            if (currentRequest) currentRequest.abort();

            mapLoader.removeClass('hidden');

            return AjaxRequest('rbGetMapCoordinates', { currentPosition: townId }).done(function (coordinates) {
                if (Array.isArray(coordinates) && coordinates.length) {
                    clearMap();

                    const polyLines = new google.maps.Polyline({
                        path: coordinates.reduce(function (coords, element) {
                            coords.push(new google.maps.LatLng(element.latitude, element.longitude));

                            return coords;
                        }, []),
                        geodesic: true,
                        strokeWeight: 5,
                        strokeOpacity: 1.0,
                        strokeColor: polyLineDrawerColor
                    });

                    historyOfMarkersAdded.push(
                        new google.maps.Marker({
                            map,
                            draggable: false,
                            animation: google.maps.Animation.DROP,
                            icon: '/wp-content/themes/ragbrai/assets/icons/marker.svg',
                            position: new google.maps.LatLng(coordinates[0].latitude, coordinates[0].longitude)
                        })
                    );

                    historyOfMarkersAdded.push(
                        new google.maps.Marker({
                            map,
                            draggable: false,
                            animation: google.maps.Animation.DROP,
                            icon: '/wp-content/themes/ragbrai/assets/icons/marker.svg',
                            position: new google.maps.LatLng(coordinates[coordinates.length - 1].latitude, coordinates[coordinates.length - 1].longitude)
                        })
                    );

                    historyOfMarkersAdded.forEach((marker, key) => {
                        marker.addListener('click', () => {
                            location.href = `${href}?section=ui-rb-towns-${key}`;
                        });
                    }, false);

                    polyLines.setMap(map);
                    zoomToObject(map, polyLines, 1);

                    historyOfPolyLines.push(polyLines);
                } else {
                    clearMap();
                }

                mapLoader.addClass('hidden');
            });
        }

        function loadData(el) {
            if (el.length) {
                const from = el.data('from');

                if (from && currentPosition !== from) {
                    currentPosition = from;

                    currentRequest = handleMapData(from, el.data('href') || false);
                }
            }
        }

        function handleTheActiveElement() {
            if (clickedItem) {
                $('.rb-map-carousel').find('.owl-item.active').removeClass('active');
                $('[data-from="' + clickedItem + '"]')
                    .parent()
                    .addClass('active');
            } else {
                $('.rb-map-carousel').find('.owl-item.active').removeClass('active').first().addClass('active');
            }
        }

        let carousel = $('.rb-map .owl-carousel')
            .owlCarousel({
                nav: true,
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    981: {
                        autoWidth: true
                    }
                },
                onInitialized: function () {
                    loadData($('.owl-carousel').find('.active .rb-map-carousel-item').first());
                    handleTheActiveElement();
                }
            })
            .on('translate.owl.carousel', function () {
                setTimeout(handleTheActiveElement); // Just to be a callback to change the order of execution
            })
            .on('translated.owl.carousel', function (e) {
                if (!clickedItem) {
                    loadData($(this).find('.active .rb-map-carousel-item').first());
                }
            });

        $('.rb-map-carousel').on('click', '.rb-map-carousel-item', function (e) {
            // if ($(e.target).hasClass('rb-map-carousel-item__route')) return;

            $(this).closest('.rb-map-carousel').find('.owl-item.active').removeClass('active');
            $(this).parent().addClass('active');

            const from = $(this).data('from');

            if (from && currentPosition !== from) {
                clickedItem = from;

                if (timeId) {
                    clearTimeout(timeId);
                }

                timeId = setTimeout(() => {
                    clickedItem = false;
                }, 500);

                currentPosition = from;

                currentRequest = handleMapData(from, $(this).data('href') || false);
                carousel.trigger('to.owl.carousel', $('.rb-map-carousel .rb-map-carousel-item').index(this));
            }
        });
    });
})(jQuery);
