export function zoomToObject(map, object, out) {
    const bounds = new google.maps.LatLngBounds();
    const points = object.getPath().getArray();

    points.forEach(point => {
        bounds.extend(point);
    });

    map.fitBounds(bounds, { top: 150 });

    // if (out) {
    //     if ((window && window.requestAnimationFrame) || requestAnimationFrame) {
    //         requestAnimationFrame(() => {
    //             map.setZoom(map.getZoom() - out);
    //         });
    //     } else {
    //         setTimeout(() => {
    //             map.setZoom(map.getZoom() - out);
    //         }); // Just a callback to change the order of execution. :)
    //     }
    // }
}
