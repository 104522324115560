import { mapStyles, polyLineDrawerColor /** mapBgColor */ } from '../styles/Map';
import { zoomToObject } from '../helpers/mapHelpers';

(function ($, mapContainer, archiveRoutesArray, mapLoader, normalMarker) {
    $(function () {
        let gCoords = [];

        let map = null;
        let coordinates = [];
        const mapMarkersIndexses = [];

        if (!mapContainer.length) {
            mapContainer = $('#google-map');
            gCoords = mapContainer.data('coords') || [];
        }

        if (mapContainer.length) {
            map = new google.maps.Map(mapContainer[0], {
                zoom: 10,
                disableDefaultUI: true,
                styles: mapStyles
                // backgroundColor: mapBgColor
            });
        }

        if (gCoords.length) {
            const bounds = new google.maps.LatLngBounds();

            gCoords.forEach(item => {
                const marker = new google.maps.Marker({
                    map,
                    draggable: false,
                    animation: google.maps.Animation.DROP,
                    icon: `/wp-content/themes/ragbrai/assets/icons/marker.svg`,
                    position: new google.maps.LatLng(item.latitude, item.longitude)
                });

                if (item.link) {
                    marker.addListener('click', () => {
                        location.href = item.link;
                    });
                }

                bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
            });

            if (gCoords.length === 1) {
                map.setCenter(new google.maps.LatLng(gCoords[0].latitude, gCoords[0].longitude));
            } else {
                map.fitBounds(bounds, { top: 150 });
            }

            mapLoader.addClass('hidden');
        } else {
            if (Array.isArray(archiveRoutesArray)) {
                archiveRoutesArray.forEach(function (route, index) {
                    if (!route.length) return;
                    if (0 === index) mapMarkersIndexses.push(index);

                    coordinates = coordinates.concat(route);
                    mapMarkersIndexses.push(mapMarkersIndexses[mapMarkersIndexses.length - 1] + route.length - 1);
                });
            }

            const polyLines = new google.maps.Polyline({
                path: coordinates.reduce(function (coords, element) {
                    coords.push(new google.maps.LatLng(element.latitude, element.longitude));

                    return coords;
                }, []),
                geodesic: true,
                strokeWeight: 5,
                strokeOpacity: 1.0,
                strokeColor: polyLineDrawerColor
            });

            mapMarkersIndexses.forEach(function (index, key) {
                const marker = new google.maps.Marker({
                    map,
                    draggable: false,
                    animation: google.maps.Animation.DROP,
                    icon: `/wp-content/themes/ragbrai/assets/icons/marker${normalMarker ? '' : key + 1}.svg`,
                    position: new google.maps.LatLng(coordinates[index].latitude, coordinates[index].longitude)
                });

                const href = $(`#town-${key + 1}`).data('href') || false;

                if (href) {
                    marker.addListener('click', () => {
                        location.href = `${href}?section=ui-rb-towns-0`;
                    });
                }

                if (window.doNotBound) {
                    map.setCenter(new google.maps.LatLng(coordinates[index].latitude, coordinates[index].longitude));
                }
            });

            if (mapMarkersIndexses.length && coordinates.length) {
                polyLines.setMap(map);

                if (!window.doNotBound) {
                    zoomToObject(map, polyLines, 1);
                }

                mapLoader.addClass('hidden');
            }
        }
    });
})(jQuery, $('#host-towns-map'), window.archiveRoutesArray || [], $('#map-loader'), window.normalMarker || false);
