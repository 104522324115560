export const mapStyles = [
    {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#000000'
            }
        ]
    },
    {
        featureType: 'administrative.province',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#E7F5FD'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: '#003594'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [
            {
                color: '#E7F5FD'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    }
];

export const polyLineDrawerColor = '#009bff';

export const mapBgColor = '#009bff';
